import {defineStore} from 'pinia'

import konfiguration from 'anbieterPfad/konfiguration';
import {konfiguration as defaultKonfig} from '@/anbieter/default/konfiguration';

const anbieterKonfiguration = {
  ...defaultKonfig,
  ...konfiguration
};

export const useBenutzerStore = defineStore('benutzer', {
  state: () => ({
    benutzerName: '',
    vorUndNachname: '',
    vertragsNummer: '',
    fehlgeschlageneLogins: 0,
    fehlgeschlagenepineingaben: 0,
    auswahlDigitalerVersand: false,
    accessToken: '',
    refreshToken: '',
    pinueberpruefungerforderlich: true,
      abmeldeIntervallId: undefined
  }),
  getters: {
    getBenutzerName: (state) => state.benutzerName,
    getVorUndNachname: (state) => state.vorUndNachname,
    getVertragsNummer: (state) => state.vertragsNummer,
  }
});

export const useAnbieterStore = defineStore('anbieter', {
  state: () => ({
    ssoAnbieter: false,
    anbieterNummer: anbieterKonfiguration.anbieternummer,
    werbeeinwilligungInRegistrierungAnzeigen: anbieterKonfiguration.werbeeinwilligungInRegistrierungAnzeigen,
    anbieterKonfig: anbieterKonfiguration,
    ssoAktiv: undefined,
    ssoAnmeldeUri: '',
    ssoAbmeldeUri: '',
    gueltigkeitDerPinInTagen: 0,
    zulaessigePinFehlversuche: 0,
    kontaktkanal: '',

  }),
  getters: {
    getAnbieternummer: (state) => state.anbieterNummer
  }
});

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    feedbackTyp: undefined,
    feedbackText: undefined
  })
});
