const konfiguration = {
  anbieternummer: '0204000087',
  hinweisWerbeeinwilligungZeigen: false,
  meineDaten: {
    beitraegeUndZulagen: {
      zulagenAnzeigen: false,
      tabellenDekoration: 'table-striped '
    }
  },
  dokumentencenter: {
    icon: ''
  },
  buttons: {
    vorangestelltesIconZeigen: false,
    preIcon: 'fa-caret-right',
    nachgestelltesIconZeigen: false,
    postIcon: 'fa-caret-right'
  },
  links: {
    vorangestelltesIconZeigen: false,
    preIcon: 'fa-caret-right',
  },
  startseite: {
    kreisUmIcons: false,
    zeilenumbruchVorAnbietername: false
  },
  titel: 'Riester-Zulagen-Portal der Öffentliche Lebensversicherung Berlin Brandenburg AG',
  footer: {
    impressum: 'https://www.feuersozietaet.de/impressum',
    datenschutz: 'https://www.feuersozietaet.de/datenschutz',
    kontakt: 'https://www.feuersozietaet.de/kontakt'
  }
}
export default konfiguration;

