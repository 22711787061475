export const konfiguration = {
    ssoAnbieter: false,
    werbeeinwilligungInRegistrierungAnzeigen: true,
    hinweisWerbeeinwilligungZeigen: true,
    navigation: {
        iconsInNavigation: true,
        navigationInHeader: false
    },
    startseite: {
        kreisUmIcons: false,
        zeilenumbruchVorAnbietername: true
    },
    meineDaten: {
        beitraegeUndZulagen: {
            zulagenAnzeigen: true,
            tabellenDekoration: 'table-striped '
        }
    },
    dokumentencenter: {
        icon: 'fa-caret-right'
    },
    buttons: {
        vorangestelltesIconZeigen: false,
        preIcon: 'fa-caret-right',
        nachgestelltesIconZeigen: true,
        postIcon: 'fa-caret-right'
    },
    links: {
        vorangestelltesIconZeigen: true,
        preIcon: 'fa-caret-right',
    },
    zulagerechner: {
        meldung: 'Sie nutzen nicht die volle Zulage, die Ihnen zusteht. Bitte wenden Sie sich an Ihren persönlichen Berater vor Ort, um Ihren optimalen Beitrag errechnen zu lassen. Die Kontakten Ihres Betreuers finden Sie in Ihrem Versicherungsschein.',
        zusaetzlicherButton: false,
        buttonZiel: '',
        buttonText: ''
    },
    titel: 'Riester-Zulagen-Portal',
    zustimmungDokumentenversandZeigen: true,
  footer: {
    impressum: '',
    datenschutz: '',
    kontakt: ''
  }
}
