import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './scss/custom.scss'
import {library} from '@fortawesome/fontawesome-svg-core'
import "bootstrap";
import axios from "axios";
import {useBenutzerStore} from '@/store'

import {
    faArrowCircleRight,
    faCalendarDays,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faCheckCircle,
    faCircle,
    faCircleChevronRight,
    faEuro,
    faExclamationCircle,
    faFileSignature,
    faFileText,
    faFolderTree,
    faHatWizard,
    faInfoCircle,
    faPhone,
    faPlusCircle,
    faPowerOff,
    faRecycle,
    faRightFromBracket,
    faTriangleExclamation,
    faUser
} from '@fortawesome/free-solid-svg-icons'
import {faFilePdf, faTrashCan} from "@fortawesome/free-regular-svg-icons";

import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import {createPinia} from 'pinia'

library.add(faCaretLeft, faHatWizard, faUser, faFileText, faEuro, faFolderTree, faFileSignature, faRecycle, faTrashCan, faInfoCircle, faCaretRight, faCalendarDays, faRightFromBracket, faFilePdf,
    faPowerOff, faCheckCircle, faArrowCircleRight, faPlusCircle, faTriangleExclamation, faCircle, faCheck, faCircleChevronRight, faPhone, faExclamationCircle);

export const app = createApp(App).use(createPinia())
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon).component('font-awesome-layers', FontAwesomeLayers).mount('#app')

const benutzerStore = useBenutzerStore();

axios.interceptors.request.use(
    (config) => {
        const token = benutzerStore.accessToken;
        if (token) {
            // @ts-ignore
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
