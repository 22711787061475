<template>
  <div class="container shadow p-0">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',

  methods: {
    // ...mapMutations(["setInitialisiert"])
  },

  computed: {
    // ...mapState(["initialisiert","angemeldet"])
  }
}
</script>

<style>
</style>
