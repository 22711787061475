import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'LoginRegistrierung',
        component: () => import(/* webpackChunkName: "loginregistrierung" */ '../views/LoginRegistrierungView.vue')
    },
    {
        path: '/emailadressebestaetigen',
        name: 'RegistrierungsBestaetigung',
        component: () => import(/* webpackChunkName: "RegistrierungsBestaetigung" */ '../views/RegistrierungsBestaetigungView.vue')
    },
    {
        path: '/willkommen',
        name: 'Startseite',
        component: () => import(/* webpackChunkName: "willkommen" */ '../views/StartseiteView.vue')
    },
    {
        path: '/meinedaten',
        name: 'MeineDaten',
        component: () => import(/* webpackChunkName: "meinedaten" */ '../views/MeineDatenView.vue')
    },
    {
        path: '/dokumentencenter',
        name: 'DokumentenCenter',
        component: () => import(/* webpackChunkName: "dokumentencenter" */ '../views/DokumentenCenterView.vue')
    },
    {
      path: '/zulageantrag/:jahr?',
      name: 'OnlineZulageantragMitJahr',
      component: () => import(/* webpackChunkName: "zulageantrag" */ '../views/OnlineZulageantragView.vue')
    },
    {
        path: '/zulagerechner',
        name: 'Zulagerechner',
        component: () => import(/* webpackChunkName: "zulagerechner" */ '../views/ZulagerechnerView.vue')
    },
    {
        path: '/benutzernamevergessen',
        name: 'BenutzernameVergessen',

        component: () => import(/* webpackChunkName: "benutzernamevergessen" */ '../views/BenutzernameVergessenView.vue')
    },
    {
        path: '/passwortzusenden',
        name: 'PasswortZusenden',
        component: () => import(/* webpackChunkName: "passwortzusenden" */ '../views/PasswortZusendenView.vue')
    },
    {
        path: '/neuespasswort',
        name: 'Passwortaenderung',
        component: () => import(/* webpackChunkName: "Passwortaenderung" */ '../views/PasswortaenderungsBestaetigungView.vue')
    },
    {
        path: '/dokumentenversand',
        name: 'Dokumentenversand',
        component: () => import(/* webpackChunkName: "dokumentenversand" */ '../views/DokumentenversandView.vue')
    },
    {
        path: '/passwortaenderungerforderlich',
        name: 'PasswortAenderungErforderlich',
        component: () => import(/* webpackChunkName: "passwortaenderungerforderlich" */ '../views/PasswortAenderungErforderlichView.vue')
    },
    {
        path: '/benutzernameaenderungbestaetigen',
        name: 'VenutzernameAenderungBestaetigen',
        component: () => import(/* webpackChunkName: "passwortaenderungerforderlich" */ '../views/BenutzernameAendernBestaetigenView.vue')
    },
    {
        path: '/emailaenderungbestaetigen',
        name: 'PasswortAenderungBestaetigen',
        component: () => import(/* webpackChunkName: "passwortaenderungerforderlich" */ '../views/EmailAendernBestaetigenView.vue')
    },
    {
      path: '/registrierungsbestaetigungerneutversenden',
      name: 'RegBestErneutVersenden',
      component: () => import(/* webpackChunkName: "passwortaenderungerforderlich" */ '../views/RegistrierungsbestaetigungErneutVersenden.vue')
    },
    {
        path: '/sso',
        name: 'SingleSignOn',
        component: () => import(/* webpackChunkName: "SingleSignOn" */ '../views/SingleSignOnView.vue')
    },
    {
        path: '/wartung',
        name: 'Wartung',
        component: () => import(/* webpackChunkName: "SingleSignOn" */ '../views/WartungsseiteView.vue')
    },
    {
        path: '/pin',
        name: 'Pin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SingleSignOn" */ '../views/PinView.vue')
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), //(process.env.BASE_URL),
    routes
})

export default router
